export default function redirect404(router: any) {
    const fullUrl = window.location.href;
    if (fullUrl.split('/').length < 5) {
        return;
    }
    const firstLocale = fullUrl.split('/')[3].length;
    const secondLocale = fullUrl.split('/')[4].length;

    if (firstLocale === 2 && secondLocale === 2) {
        router.push('/404/');
    }
}
