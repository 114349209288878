import Homepage from '@components/layouts/homepage';
import { getHomePageProps } from '@data/homePageProps';
import redirect404 from 'lib/helper/404redirect';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { IndexProps } from '../lib/types/page/indexprops';

export default function Index({
    pageData,
    intro,
    title,
    emailText,
    content,
    categoryList,
    langList,
    currentLang,
    metaDescription,
    topSiteDataList
}: {
    pageData: IndexProps;
    intro: string;
    title: string;
    emailText: string;
    content: string;
    categoryList: any;
    langList: any;
    currentLang: string;
    metaDescription: string;
    topSiteDataList: any;
}): JSX.Element {
    const router = useRouter();

    useEffect(() => {
        redirect404(router);
    }, []);

    return (
        <Homepage
            pageData={pageData}
            intro={intro}
            introEmail={emailText}
            content={content}
            contentEmail={emailText}
            categoryList={categoryList}
            title={title}
            currentLang={currentLang}
            langList={langList}
            metaDescription={metaDescription}
            topSiteDataList={topSiteDataList}
        />
    );
}

// Get the data required to populate the page
export const getStaticProps: GetStaticProps = async (context: any) => {
    // Get the data from the respository to populate the page
    const props = await getHomePageProps(context.locale);
    if (!props.pageData) {
        return {
            notFound: true
        };
    }
    return {
        props: props,
        revalidate: 300
    };
};
