import Base from '@components/layouts/base';
import CategoryList from '@components/partials/category/list';
import TopSitesHome from '@components/partials/category/topSitesHome';
import ContentDescription from '@components/partials/common/contentDescription';
import Footer from '@components/partials/common/footer';
import Topbar from '@components/partials/common/topbar';
import Header from '@components/partials/header/header';
import { IndexProps } from 'lib/types/page/indexprops';
import Head from 'next/head';

export default function Homepage({
    pageData,
    intro,
    introEmail,
    content,
    contentEmail,
    categoryList,
    title,
    currentLang,
    langList,
    metaDescription,
    topSiteDataList
}: {
    pageData: IndexProps;
    intro: any;
    introEmail: any;
    content: any;
    contentEmail: any;
    categoryList: any;
    title: string;
    currentLang: string;
    langList: any;
    metaDescription: string;
    topSiteDataList: any;
}): JSX.Element {
    return (
        <Base>
            <Head>
                <title>{title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                {metaDescription && <meta name="description" content={metaDescription} />}
                <meta name="robots" content={pageData.metaRobots} />
                {currentLang !== 'en' && (
                    <meta httpEquiv="Content-Language" content={currentLang}></meta>
                )}
                <link
                    rel="canonical"
                    href={
                        process.env.NEXT_PUBLIC_APP_DOMAIN +
                        (currentLang !== 'en' ? currentLang + '/' : '')
                    }
                />
                <link
                    rel="alternate"
                    href={process.env.NEXT_PUBLIC_APP_DOMAIN}
                    hrefLang="x-default"
                />
                {langList.map((lang: any) => {
                    return (
                        <link
                            key={lang.slug}
                            rel="alternate"
                            href={
                                process.env.NEXT_PUBLIC_APP_DOMAIN +
                                (lang.slug != 'en' ? lang.slug + '/' : '')
                            }
                            hrefLang={lang.hreflang}
                        />
                    );
                })}
            </Head>
            <div className="wrapper">
                <Topbar
                    categoryList={categoryList}
                    homeSlug={'/' + currentLang}
                    isHomePage={true}
                />
                <Header intro={intro} introEmail={introEmail} isHome={true} />
                <CategoryList categoryList={categoryList} language={currentLang} />
                <TopSitesHome topSiteDataList={topSiteDataList} currentLang={currentLang} />
                <ContentDescription
                    content={content}
                    contentEmail={contentEmail}
                    mascot={true}
                    currentLang={currentLang}
                />
                <Footer />
            </div>
        </Base>
    );
}
