import classes from '@styles/scss/site/cardRating.module.scss';

export default function RatingStars({ siteReviewData }: { siteReviewData: any }): JSX.Element {
    const getScoreClassName = (myScore: number, maxScore: number) => {
        if (myScore >= maxScore * 0.61) {
            return classes.green;
        } else if (myScore >= maxScore * 0.41 && myScore <= maxScore * 0.6) {
            return classes.orange;
        } else if (myScore > 0 && myScore <= maxScore * 0.4) {
            return classes.red;
        } else {
            return classes.black;
        }
    };
    const FullStar = Math.floor(siteReviewData.scoreMfsc);
    const HalfStar = Math.ceil(siteReviewData.scoreMfsc);
    const list = [];
    for (let i = 1; i <= 5; i++) {
        if (i <= FullStar) {
            list.push(
                <div className={classes.full} data-index={i} key={i}>
                    ☆
                </div>
            );
        }
        if (i > FullStar) {
            if (i <= HalfStar) {
                list.push(
                    <div className={classes.half} data-index={i} key={i}>
                        ☆
                    </div>
                );
            } else {
                list.push(
                    <div className={classes.ratingStar} data-index={i} key={i}>
                        ☆
                    </div>
                );
            }
        }
    }

    return (
        <div className={classes.siteRating} data-sid="rabbitscams" id="rating">
            <div className={classes.rateTitle}>
                <strong>{siteReviewData.mfscTitle}</strong>
            </div>
            {siteReviewData.scoreMfsc > 0 ? (
                <div className={classes.rateStars} data-rating={siteReviewData.scoreMfsc}>
                    {list}
                </div>
            ) : (
                <p>Not Yet Scored</p>
            )}
            <div className={classes.trioScores}>
                <div className={classes.singleScore}>
                    <div className={classes.titleScore}>{siteReviewData.contentTitle}</div>
                    <div className={classes.score}>
                        {siteReviewData.scoreContent == 0 ? (
                            <p>N/A</p>
                        ) : (
                            <p className={getScoreClassName(siteReviewData.scoreContent, 15)}>
                                {siteReviewData.scoreContent}/15
                            </p>
                        )}
                    </div>
                </div>
                <div className={classes.singleScore}>
                    <div className={classes.titleScore}>{siteReviewData.featuresTitle}</div>
                    <div className={classes.score}>
                        {siteReviewData.scoreFeature == 0 ? (
                            <p>N/A</p>
                        ) : (
                            <p className={getScoreClassName(siteReviewData.scoreFeature, 5)}>
                                {siteReviewData.scoreFeature}/5
                            </p>
                        )}
                    </div>
                </div>
                <div className={classes.singleScore}>
                    <div className={classes.titleScore}>{siteReviewData.valueTitle}</div>
                    <div className={classes.score}>
                        {siteReviewData.scoreValue == 0 ? (
                            <p>N/A</p>
                        ) : (
                            <p className={getScoreClassName(siteReviewData.scoreValue, 10)}>
                                {siteReviewData.scoreValue}/10
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
