import RatingStars from '@components/partials/site/rating/ratingStars';
import styles from '@styles/scss/category/topSitesHome.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import React from 'react';
import { useEffect, useState } from 'react';

export default function TopSiteHomeCard({
    site,
    languageFile
}: {
    site: any;
    languageFile: any;
}): JSX.Element {
    const cookies = parseCookies();
    const [updatedTourlink, setUpdatedTourlink] = useState(site.tourlink);

    useEffect(() => {
        if (cookies.u) {
            setUpdatedTourlink(site.tourlink.replace('{exid}', cookies.u));
        }
    }, [cookies.u, site.tourlink]);

    const externalLink = site.useOpensite ? '/out/' + site.slug : updatedTourlink;
    const internalLink = `/${site.categorySlug}/${site.slug}`;

    return (
        <div className={styles.topSite} key={site.slug}>
            <div className={styles.topSiteTitle}>
                {site.categoryName}: {site.name}
            </div>
            <div className={styles.topSiteCard}>
                <div className={styles.image}>
                    <Link prefetch={false} href={externalLink}>
                        <a
                            data-site-name={site.name}
                            data-site-category={site.categoryName}
                            target="_blank"
                            rel={
                                site.useOpensite
                                    ? 'nofollow noopener noreferrer'
                                    : 'noopener noreferrer'
                            }
                            data-event-category="User Action"
                            data-event-action="outbound_click"
                            data-event-label={site.name}>
                            <Image
                                className={styles.siteImg}
                                src={site.image}
                                alt={site.name}
                                width={268}
                                height={105}
                                unoptimized={true}
                                objectFit="cover"
                            />
                        </a>
                    </Link>
                </div>
                <div className={styles.cardContent}>
                    <div className={styles.rating}>
                        <RatingStars siteReviewData={site} />
                    </div>
                    <div className={styles.pros}>
                        <ul>
                            {site.pro1 && <li>{site.pro1}</li>}
                            {site.pro2 && <li>{site.pro2}</li>}
                            {site.pro3 && <li>{site.pro3}</li>}
                        </ul>
                    </div>
                    <div className={styles.spacer}></div>

                    <Link prefetch={false} href={externalLink}>
                        <a
                            className={styles.visitButton}
                            data-site-name={site.name}
                            data-site-category={site.categoryName}
                            target="_blank"
                            rel={
                                site.useOpensite
                                    ? 'nofollow noopener noreferrer'
                                    : 'noopener noreferrer'
                            }
                            data-event-category="User Action"
                            data-event-action="outbound_click"
                            data-event-label={site.name}>
                            {languageFile.visit_website}
                        </a>
                    </Link>
                    <Link
                        prefetch={false}
                        href={!site.isReviewActive ? externalLink : internalLink}>
                        <a
                            className={styles.readButton}
                            data-site-name={site.name}
                            data-site-category={site.categoryName}
                            target="_blank"
                            rel={
                                site.useOpensite
                                    ? 'nofollow noopener noreferrer'
                                    : 'noopener noreferrer'
                            }>
                            {languageFile.read_review}
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    );
}
