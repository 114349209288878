import styles from '@styles/scss/category/topSitesHome.module.scss';

import TopSiteHomeCard from './topSiteHomeCard';
const languageFiles = (require as any).context('public/locales', false, /\.json$/);

export default function TopSitesHome({
    topSiteDataList,
    currentLang
}: {
    topSiteDataList: any;
    currentLang: string;
}): JSX.Element {
    const languageFile = languageFiles(`./${currentLang}.json`);

    return (
        <div className={styles.topSitesWrapper}>
            <div className={styles.categoryBanner}>{languageFile.sites_by_category}</div>
            <div className={styles.topThreeSites}>
                {Object.values(topSiteDataList).map((site: any) => (
                    <TopSiteHomeCard key={site.slug} site={site} languageFile={languageFile} />
                ))}
            </div>
        </div>
    );
}
