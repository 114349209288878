import styles from '@styles/scss/common/_header.module.scss';

export default function Header({
    intro,
    introEmail,
    isHome
}: {
    intro: any;
    introEmail?: any;
    isHome?: boolean;
}): JSX.Element {
    const email = '<a href="mailto:paul@myfavsexcams.xxx">' + introEmail + '</a>';
    return (
        <>
            <div className={styles.container}>
                <div className={isHome ? styles.homeBubble : styles.bubble}>
                    <div
                        className={styles.content}
                        dangerouslySetInnerHTML={{
                            __html: intro.replace('{email}', email)
                        }}></div>
                </div>
            </div>
        </>
    );
}
